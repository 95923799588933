<template>
  <div class="helpCenter">
    <v-header @searchFunc="searchFunc"></v-header>
    <section class="search-result">
      <div class="result-title">共搜出 {{ total }} 个结果</div>
      <div class="result-container" v-if="list.length > 0" v-loadmore="loadMore">
        <ul class="body-wrapper">
          <li v-for="(item, index) in list" :key="index" @click="goDetail(item)">
            <div class="question-title">
              <span><img src="../../assets/images/pointer.png" alt="" /></span>
              <div v-html="ruleTitle(item.title)"></div>
            </div>
            <div class="question-form" v-if="item.docClass && item.docClass.items.length">
              来自：{{ item.docClass.text }} - {{ item.docClass.items[0].text }}
            </div>
          </li>
        </ul>
      </div>
      <div class="search-nothing" v-else>
        <img src="../../assets/images/nothing.png" alt="" />
        <div>暂无相关结果</div>
      </div>
    </section>
  </div>
</template>

<script>
import header from "../../components/pc/header.vue";
import { searchQue } from "../../api/index"; //getSearch,
export default {
  components: {
    "v-header": header,
  },
    data () {
        return {
            datalength: "",
            list: [],
            keyWord: "",
            loading2: false,
            anchor: null,
            loading: false,
            finished: false,
            total: 0,
            test: {
                id: 1,
                title: '产品手册手册',
                docClass: {
                    classifyLevel: 2,
                    sortId: 1,
                    text: '账号相关1',
                    value: 1,
                    items: [
                        {
                            classifyLevel: 2,
                            sortId: 1,
                            text: '资产相关1',
                            value: 1.1,
                        },
                        {
                            classifyLevel: 2,
                            sortId: 2,
                            text: '资产相关2',
                            value: 1.2,
                        },
                        {
                            classifyLevel: 2,
                            sortId: 3,
                            text: '资产相关3',
                            value: 1.3,
                        }
                    ],
                },
            }
        };
    },
  created() {},
  mounted() {
    this.keyWord = this.$route.query.keyword;
    if (this.keyWord) {
      this.searchResult();
    }
  },
  methods: {
    //当前页搜索
    searchFunc(keyword) {
      this.list = [];
      this.anchor = "";
      this.keyWord = keyword;
      this.searchResult();
    },
    //搜索结果
    searchResult() {
      const parmas = {
        keyWord: this.keyWord,
        "paging.anchor": this.anchor,
        "paging.count": 20,
        "paging.direction": "DOWN",
        platformId: "PC",
      };
      searchQue(parmas).then((res) => {
        if (res.code == 200) {
          if (res.result.items) {
            this.datalength = res.result.items.length;
            this.list = this.list.concat(res.result.items);
          }

          this.total = res.result.total;
          this.loading2 = false;
          // 加载状态结束
          this.loading = false;
          if (res.result.next && res.result.next.anchor) {
            this.anchor = res.result.next.anchor;
          } else {
            // 数据全部加载完成
            this.finished = true;
          }
        }
      });
    },
    //跳转问题详情
    goDetail(item) {
      this.$router.push({
        path: "/home",
        query: {
          classify: item.docClass&&item.docClass.value?item.docClass.value: "",
          type: item.docClass&&item.docClass.items[0].value?item.docClass.items[0].value : "",
          id: item.id,
        },
      });
    },
    //关键字高亮
    ruleTitle(title) {
      let titleString = title;
      if (!titleString) {
        return "";
      }
      if (this.keyWord && this.keyWord.length > 0) {
        // 匹配关键字正则
        let replaceReg = new RegExp(this.keyWord, "g");
        // 高亮替换v-html值
        let replaceString = "<span>" + this.keyWord + "</span>";
        // 替换
        titleString = titleString.replace(replaceReg, replaceString);
      }
      return titleString;
    },
    //加载更多
    loadMore() {
        
      if (this.datalength < 20) {
        return;
      }
      this.searchResult();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .search-result {
  height: calc(100vh - 100px);
  margin: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  .result-title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    padding-bottom: 16px;
  }
  .result-container {
        width: 100%;
        height: calc(100vh - 200px);
        overflow-x: hidden;
        overflow-y: auto;
    .body-wrapper{
    }
    li {
      border-bottom: 1px solid #e5e5e5;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      margin-top: 16px;
      cursor: pointer;
    }
    .question-title {
      display: flex;
      padding-bottom: 16px;
      div {
        span {
          color: #0084ff;
        }
      }
    }
    .question-form {
      padding-bottom: 16px;
      padding-left: 14px;
    }
  }
}
::v-deep .qa-container {
  padding: 0 20px;
  .el-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-main {
    .title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .el-collapse,
  .el-collapse-item__header,
  .el-collapse-item__wrap {
    border: none;
  }
}
.list {
  margin-top: 20px;
  padding: 16px 0px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;

  color: #222222;
  .title {
    padding: 0 20px 0px 20px;
    background: url("../../assets/images/search-icon.png") left center no-repeat;
    background-size: 14px 14px;
  }
  p {
    padding: 16px 20px 0;
  }
}
.search-nothing {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  img {
    margin-bottom: 24px;
  }
}
</style>
