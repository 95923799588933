var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "helpCenter" },
    [
      _c("v-header", { on: { searchFunc: _vm.searchFunc } }),
      _c("section", { staticClass: "search-result" }, [
        _c("div", { staticClass: "result-title" }, [
          _vm._v("共搜出 " + _vm._s(_vm.total) + " 个结果")
        ]),
        _vm.list.length > 0
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "loadmore",
                    rawName: "v-loadmore",
                    value: _vm.loadMore,
                    expression: "loadMore"
                  }
                ],
                staticClass: "result-container"
              },
              [
                _c(
                  "ul",
                  { staticClass: "body-wrapper" },
                  _vm._l(_vm.list, function(item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        on: {
                          click: function($event) {
                            return _vm.goDetail(item)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "question-title" }, [
                          _vm._m(0, true),
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.ruleTitle(item.title))
                            }
                          })
                        ]),
                        item.docClass && item.docClass.items.length
                          ? _c("div", { staticClass: "question-form" }, [
                              _vm._v(
                                " 来自：" +
                                  _vm._s(item.docClass.text) +
                                  " - " +
                                  _vm._s(item.docClass.items[0].text) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          : _c("div", { staticClass: "search-nothing" }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/nothing.png"),
                  alt: ""
                }
              }),
              _c("div", [_vm._v("暂无相关结果")])
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        attrs: { src: require("../../assets/images/pointer.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }